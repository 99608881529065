body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* index.css */

/* index.css */


/* index.css */

/* ... (previous styles) ... */

/* Custom gradient background for the entire application */
/* index.css */

/* ... (previous styles) ... */

/* Custom gradient background for the entire application */








/* Example: Set a default text color for the entire application */
body {
  overflow-x: hidden;
}
