.navbar {
  display: flex;
  justify-content: center; /* Center the navbar horizontally */
  align-items: center; /* Center the navbar vertically */
  padding: 10px 20px; /* Adjust padding to maintain thin navbar */
  background-color: #222;
  background-image: linear-gradient(to right, #000000, #3a3a3a);
  height: 50px; /* Adjust height of the navbar */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  position: relative;
  font-family: Arial, sans-serif;
  z-index: 1000;
}

.navbar-logo {
  position: absolute;
  left: 130px; /* Position to the left */
  transform: translateX(-50%);
  pointer-events: none; /* Make the logo non-interactive */
  width: 120px; /* Default width of the logo */
}

.logo-image {
  width: 80px; /* Default width of the logo */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Add some margin to separate the logo from other elements */
  pointer-events: none; /* Make the logo non-interactive */
}

.navbar-toggle {
  display: none; /* Hidden by default, visible on small screens */
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 768px) {
 

  .logo-image {
    width: 100px; /* Adjust width for smaller screens */
    margin-top: 5px; /* Adjust margin for smaller screens */
  }

  .navbar-toggle {
    display: block; /* Show toggle */
    position: absolute;
    left: 20px; /* Position to the left */
    top: 50%;
    transform: translateY(-50%);
  }

  .navbar-links.show {
    display: flex; /* Display when menu is toggled */
  }

  .profile-section {
    font-size: 12px; /* Reduce font size */
  }

  .profile-icon-wrapper {
    width: 24px; /* Reduce width */
    height: 24px; /* Reduce height */
  }
}
.right-align {
  margin-left: auto;
}

.navbar-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.navbar-link {
  margin-left: 20px;
}

.navbar-link a, .logout-btn {
  color: #fff;
  text-decoration: none;
  padding: 5px;
  border-bottom: 2px solid transparent;
}

.navbar-link a:hover, .logout-btn:hover {
  border-radius: 5px;
  color: #a09f9f;
}

.profile-section {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  right: 20px;
}

.profile-icon-wrapper {
  background-color: #333;
  border-radius: 50%;
  width: 32px; /* Adjust width */
  height: 32px; /* Adjust height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-menu {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #fff; /* White background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 200px;
  z-index: 1001;
  color: #222;
}

.dropdown-menu li {
  color: #000; /* Black text color */
  padding: 10px 20px;
  cursor: pointer;
}

/* Removing the hover effect to keep text black and background white */
.dropdown-menu li:hover {
  background-color: #333;
  color: #000; /* Keep text black */
}

.profile-section:hover .dropdown-menu {
  display: block;
  color: black;
  background-color: #222;
}

@media (max-width: 768px) {
  .dropdown-menu li {
    font-size: 14px;
  }
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.navbar.open ~ .overlay {
  display: block;
}

.navbar-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%; /* Ensure full width for center alignment */
  justify-content: center; /* Center the links */
}

/* This style is for when the menu is shown (mobile view) */
.navbar-links.show {
  flex-direction: column;
}

/* Additional styling to handle authenticated state if needed */
.center-links {
  justify-content: flex-start; /* Align items to start if specific styling is needed */
}

.login-btn {
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  margin-left: auto; /* This pushes the button to the right */
}
